document.addEventListener('DOMContentLoaded', function() {
    const rows = document.querySelectorAll('tr[href]');
    rows.forEach(function(row) {
        row.style.cursor = 'pointer';
        row.addEventListener('click', function(e) {
            if (!e.target.tagName === 'A' ||  !e.target.hasAttribute('href')) {
                window.location.href = row.getAttribute('href');
            }
        });
    });
});
